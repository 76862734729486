import subcab1 from "../../assets/images/Products/Flat Copper Submersible Cables 1.png";
import subcab2 from "../../assets/images/Products/Flat Copper Submersible Cables 2.png";
import c2 from "../../assets/images/Products/Copper Round Two Core Cables.png";
import c3 from "../../assets/images/Products/Copper Round Three Core Cables.png";
import c4 from "../../assets/images/Products/Copper Round Four Core Cables.png";
import al2 from "../../assets/images/Products/Round Aluminum Two Core Cables.png";
import al3 from "../../assets/images/Products/Round Aluminum Three Core Cables.png";
import al4 from "../../assets/images/Products/Round Aluminum Four Core Cables.png";
import hr1 from "../../assets/images/House Wire 1.png";
import hr2 from "../../assets/images/House Wire 2.png";
import hr3 from "../../assets/images/House Wire 3.png";
import hr4 from "../../assets/images/House Wire 4.png";

export const ProductsData = [
    {
        img: subcab1,
        title: "Submersible Cables",
        height: '270px',
        des: "3.3Kv to 33Kv Grade- (BASEC/KEMA/CPRl/ERDA tested) three core Copper / Aluminium Conductor,Conductor screened with extruded semi-conducting compound, XLPE Insulated, insulation screened with semiconducting compound in combination with copper tape (0.3 KA for One Sec.), PVC ST2/FR/FRLSH/LSZH Inner Sheathed armoured/ GI Steel (Aluminium in case of single core) FLAT/Round Armoured and Overall PVC ST2/ FR/ FRLSH/ LSZH sheathed cable conforming to IS:7098/ll/85, IEC 60502-2/ BS 7835 From sizes 35 sq.mm to 1200 Sq.mm, above cables can be supplied with lead sheath whenever required.",
        path: '/products/cables/flat-copper-submersible-cable'
    },
    {
        img: subcab2,
        title: "Submersible Cables",
        height: '270px',
        des: "3.3Kv to 33Kv Grade- (BASEC/KEMA/CPRl/ERDA tested) three core Copper / Aluminium Conductor,Conductor screened with extruded semi-conducting compound, XLPE Insulated, insulation screened with semiconducting compound in combination with copper tape (0.3 KA for One Sec.), PVC ST2/FR/FRLSH/LSZH Inner Sheathed armoured/ GI Steel (Aluminium in case of single core) FLAT/Round Armoured and Overall PVC ST2/ FR/ FRLSH/ LSZH sheathed cable conforming to IS:7098/ll/85, IEC 60502-2/ BS 7835 From sizes 35 sq.mm to 1200 Sq.mm, above cables can be supplied with lead sheath whenever required.",
        path: '/products/cables/flat-copper-submersible-cable'
    },
    {
        img: c4,
        title: "Copper Round  Cables",
        height: '500px',
        des: "3.3Kv to 33Kv Grade- (BASEC/KEMA/CPRl/ERDA tested) three core Copper / Aluminium Conductor,Conductor screened with extruded semi-conducting compound, XLPE Insulated, insulation screened with semiconducting compound in combination with copper tape (0.3 KA for One Sec.), PVC ST2/FR/FRLSH/LSZH Inner Sheathed armoured/ GI Steel (Aluminium in case of single core) FLAT/Round Armoured and Overall PVC ST2/ FR/ FRLSH/ LSZH sheathed cable conforming to IS:7098/ll/85, IEC 60502-2/ BS 7835 From sizes 35 sq.mm to 1200 Sq.mm, above cables can be supplied with lead sheath whenever required.",
        path: '/products/cables/copper-round-four-core-cable'
    },
    {
        img: c3,
        title: "Copper Round  Cables",
        height: '500px',
        des: "3.3Kv to 33Kv Grade- (BASEC/KEMA/CPRl/ERDA tested) three core Copper / Aluminium Conductor,Conductor screened with extruded semi-conducting compound, XLPE Insulated, insulation screened with semiconducting compound in combination with copper tape (0.3 KA for One Sec.), PVC ST2/FR/FRLSH/LSZH Inner Sheathed armoured/ GI Steel (Aluminium in case of single core) FLAT/Round Armoured and Overall PVC ST2/ FR/ FRLSH/ LSZH sheathed cable conforming to IS:7098/ll/85, IEC 60502-2/ BS 7835 From sizes 35 sq.mm to 1200 Sq.mm, above cables can be supplied with lead sheath whenever required.",
        path: '/products/cables/copper-round-three-core-cable'
    },
    {
        img: c2,
        title: "Copper Round  Cables",
        height: '270px',
        des: "3.3Kv to 33Kv Grade- (BASEC/KEMA/CPRl/ERDA tested) three core Copper / Aluminium Conductor,Conductor screened with extruded semi-conducting compound, XLPE Insulated, insulation screened with semiconducting compound in combination with copper tape (0.3 KA for One Sec.), PVC ST2/FR/FRLSH/LSZH Inner Sheathed armoured/ GI Steel (Aluminium in case of single core) FLAT/Round Armoured and Overall PVC ST2/ FR/ FRLSH/ LSZH sheathed cable conforming to IS:7098/ll/85, IEC 60502-2/ BS 7835 From sizes 35 sq.mm to 1200 Sq.mm, above cables can be supplied with lead sheath whenever required.",
        path: '/products/cables/copper-round-two-core-cable'
    },
    {
        img: al4,
        title: "Round aluminium  Cables",
        height: '500px',
        des: "3.3Kv to 33Kv Grade- (BASEC/KEMA/CPRl/ERDA tested) three core Copper / Aluminium Conductor,Conductor screened with extruded semi-conducting compound, XLPE Insulated, insulation screened with semiconducting compound in combination with copper tape (0.3 KA for One Sec.), PVC ST2/FR/FRLSH/LSZH Inner Sheathed armoured/ GI Steel (Aluminium in case of single core) FLAT/Round Armoured and Overall PVC ST2/ FR/ FRLSH/ LSZH sheathed cable conforming to IS:7098/ll/85, IEC 60502-2/ BS 7835 From sizes 35 sq.mm to 1200 Sq.mm, above cables can be supplied with lead sheath whenever required.",
        path: '/products/cables/round-aluminium-four-core-cable'
    },
    {
        img: al3,
        title: "Round aluminium  Cables",
        height: '500px',
        des: "3.3Kv to 33Kv Grade- (BASEC/KEMA/CPRl/ERDA tested) three core Copper / Aluminium Conductor,Conductor screened with extruded semi-conducting compound, XLPE Insulated, insulation screened with semiconducting compound in combination with copper tape (0.3 KA for One Sec.), PVC ST2/FR/FRLSH/LSZH Inner Sheathed armoured/ GI Steel (Aluminium in case of single core) FLAT/Round Armoured and Overall PVC ST2/ FR/ FRLSH/ LSZH sheathed cable conforming to IS:7098/ll/85, IEC 60502-2/ BS 7835 From sizes 35 sq.mm to 1200 Sq.mm, above cables can be supplied with lead sheath whenever required.",
        path: '/products/cables/round-aluminium-three-core-cable'
    },
    {
        img: al2,
        title: "Flat aluminium  Cables",
        height: '500px',
        des: "3.3Kv to 33Kv Grade- (BASEC/KEMA/CPRl/ERDA tested) three core Copper / Aluminium Conductor,Conductor screened with extruded semi-conducting compound, XLPE Insulated, insulation screened with semiconducting compound in combination with copper tape (0.3 KA for One Sec.), PVC ST2/FR/FRLSH/LSZH Inner Sheathed armoured/ GI Steel (Aluminium in case of single core) FLAT/Round Armoured and Overall PVC ST2/ FR/ FRLSH/ LSZH sheathed cable conforming to IS:7098/ll/85, IEC 60502-2/ BS 7835 From sizes 35 sq.mm to 1200 Sq.mm, above cables can be supplied with lead sheath whenever required.",
        path: '/products/cables/round-aluminium-two-core-cable'
    },

]

export const HouseWiresData = [
    {
        img: hr1,
        path: '/products/wires',
    },
    {
        img: hr2,
        path: '/products/wires',
    },
    {
        img: hr3,
        path: '/products/wires',
    },
    {
        img: hr4,
        path: '/products/wires',
    }
]