import React from "react";
import BreadcrbCom from "../Breadcrumb/BreadcrubCom";
import { ProductsData } from "./ProductsData";

const Cables = () => {
    return (
        <>
            <div className="product_bg_wrapper">
                <BreadcrbCom link1="/" link2='/products' link1text="Home" link2text="Our Products" active="Cables" title="Cables" />
            </div>
            <div className="container products_cables__wrapper">
                {
                    ProductsData.map((data) => {
                        return (
                            <div className="row">
                                <div className="col-lg-3 col-md-4">
                                    <img src={data.img} alt={data.title} className="cables__main--img"/>
                                </div>
                                <div className="col-lg-9 col-md-8">
                                    <div className="cables__main--title">{data.title}</div>
                                    <div className="">{data.des}</div>
                                    <button className='connect_btn mt-3 ctm__btn'>
                                        See Pricelist
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default Cables;