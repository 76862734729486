import React from 'react';
import Slider from "react-slick";
import Skeleton from 'react-loading-skeleton'
import "slick-carousel/slick/slick.css";
import 'react-loading-skeleton/dist/skeleton.css'
import "slick-carousel/slick/slick-theme.css";
import './heroslider.css';
import slider1 from "../../assets/images/slider1.png";
import slider2 from "../../assets/images/slider2.png";
import slider3 from "../../assets/images/slider3.png";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'flex', background: '#fe8a0f', borderRadius: '50%', justifyContent: 'center', alignItems: 'center' }}
            onClick={onClick}
        >
            <FaChevronRight fontSize={25} color='#ffffff' />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'flex', background: "#fe8a0f", borderRadius: '50%', justifyContent: 'center', alignItems: 'center' }}
            onClick={onClick}
        >
            <FaChevronLeft fontSize={25} color='#ffffff' />
        </div>
    );
}

const HeroSlider = () => {
    // Ensure `options` and other props are properly defined
    const settings = {
        dots: true,
        // arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        // className: "center",
        // centerMode: true,
        infinite: true,
        // centerPadding: "20px",
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };
    return (
        <div className="container Hero__slider--mainwrapper">
            <Slider {...settings}>
                <div style={{
                    // height: '500px'
                }}>
                    <h3>{<img src={slider1} className='slider__img' /> || <Skeleton height={500} />}</h3>
                </div>
                <div>
                    <h3>{<img src={slider2} className='slider__img' /> || <Skeleton height={500} />}</h3>
                </div>
                <div>
                    <h3>{<img src={slider3} className='slider__img' /> || <Skeleton height={500} />}</h3>
                </div>
            </Slider>
        </div>
    )
};


export default HeroSlider;