import React, { useState } from "react";
import BreadcrbCom from "../Breadcrumb/BreadcrubCom";
import { CgChevronDoubleRight } from "react-icons/cg";
import cable1 from "../../assets/images/Products/Flat Copper Submersible Cables 1 box.png";
import cable2 from "../../assets/images/Products/Flat Copper Submersible Cables 2 box.png";
import fcsubinside from "../../assets/images/Products/Flat Copper Submersible Cables 1 inside.png";
import fcsubinside2 from "../../assets/images/Products/Flat Copper Submersible Cables 2 inside.png";
import './fcsubmersiblecables.css';
import DownloadCatalogue from "./DownloadCatalogue";

export const TabCom = ({ label, onClick, isActive }) => {
    return (
        <li className={`tab ${isActive ? 'active' : ''}`} onClick={onClick}>
            {label}
        </li>
    );
};

export const TabContent = ({ content }) => {
    return (
        <div className="tab-content">
            {content}
        </div>
    );
};


const FCSubmersiblecable = () => {
    const [activeTab, setActiveTab] = useState('Features');
    const [selectedSwitch, setSelectedSwitch] = useState(0);

    const tabData = {
        'Features': (
            <div>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} />MANUFACTURED FROM BRIGHT ANNEALED 99.97 % PURE COPPER CONDUCTORS AND HENCE,
                    OFFER LOW CONDUCTOR RESISTANCE.</p>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} />OUTER SHEATH CONSISTS OF HIGHLY ABRASION RESISTANT PVC COMPOUND IMPERVIOUS TO GREASE, OIL AND WATER ETC.</p>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} />VERY GOOD INSULATION PROPERTIES WHEN SUBMERGED IN water.</p>
            </div>
        ),
        'Applications': (
            <div>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /> THESE CABLES ARE USED FOR SUBMERSIBLE PUMP INSTALLATION IN  BOREWELL AND OPENWELL
                </p>
            </div>
        ),
        'Applicable Standard': (
            <div>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} />
                    ISO 9001:2015
                </p>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} />
                    CE
                </p>
            </div>
        ),
        'Technical Info': (
            <table>
                <thead>
                    <tr>
                        <th rowspan="2">Nominal Area Of Copper Conductor (Sq.mm)</th>
                        <th rowspan="2">Number / Size of Wire For Each Core (mm)</th>
                        <th rowspan="2">Thickness Of Insulation (Nominal) (mm)</th>
                        <th rowspan="2">Thickness Of Sheath (Nominal) (mm)</th>
                        <th colspan="2">PVC Sheath Approx. Overall Dimension (Nom.) (mm)</th>
                        <th rowspan="2">Max. Conductor Resistance At 20° ohm / km</th>
                        <th rowspan="2">Current Carrying Capacity at 40°C in Amps.</th>
                    </tr>
                    <tr>
                        <th>Width (mm)</th>
                        <th>Height (mm)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>14/0.30*</td>
                        <td>0.6</td>
                        <td>1</td>
                        <td>10.50</td>
                        <td>4.90</td>
                        <td>18.10</td>
                        <td>11</td>
                    </tr>
                    <tr>
                        <td>1.5</td>
                        <td>22/0.30*</td>
                        <td>0.6</td>
                        <td>1</td>
                        <td>11.70</td>
                        <td>5.20</td>
                        <td>12.10</td>
                        <td>14</td>
                    </tr>
                    <tr>
                        <td>2.5</td>
                        <td>36/0.30*</td>
                        <td>0.7</td>
                        <td>1.1</td>
                        <td>13.70</td>
                        <td>6.00</td>
                        <td>7.41</td>
                        <td>18</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>56/0.30**</td>
                        <td>0.8</td>
                        <td>1.2</td>
                        <td>15.70</td>
                        <td>6.90</td>
                        <td>4.95</td>
                        <td>26</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>84/0.30**</td>
                        <td>0.8</td>
                        <td>1.3</td>
                        <td>18.90</td>
                        <td>8.20</td>
                        <td>3.30</td>
                        <td>31</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>140/0.30**</td>
                        <td>1</td>
                        <td>1.4</td>
                        <td>24.10</td>
                        <td>11.00</td>
                        <td>1.91</td>
                        <td>42</td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>226/0.30**</td>
                        <td>1</td>
                        <td>1.6</td>
                        <td>27.50</td>
                        <td>12.40</td>
                        <td>1.20</td>
                        <td>57</td>
                    </tr>
                    <tr>
                        <td>25</td>
                        <td>354/0.30**</td>
                        <td>1.2</td>
                        <td>2</td>
                        <td>35.00</td>
                        <td>14.60</td>
                        <td>0.780</td>
                        <td>72</td>
                    </tr>
                    <tr>
                        <td>35</td>
                        <td>495/0.30**</td>
                        <td>1.2</td>
                        <td>2</td>
                        <td>40.50</td>
                        <td>17.00</td>
                        <td>0.554</td>
                        <td>90</td>
                    </tr>
                    <tr><td colSpan='7'><p>* Class - 2 Flexible Copper Conductor As Per IS 8130</p></td></tr>
                    <tr><td colSpan='7'><p>** Class - 5 Flexible Copper Conductor As Per IS 8130</p></td></tr>
                </tbody>
            </table>
        ),
    };

    const images = [cable1, cable2];
    const images1 = [fcsubinside, fcsubinside2]

    return (
        <>
            <div className="about_bg_wrapper">
                <BreadcrbCom link1="/" link2='/products' link1text="Home" link2text="Our Products" active="Cables" title="Flat Copper Submersible Cables" />
            </div>
            <div className="container fcsc__wrapper">
                <div className="row mb-5">
                    <div className="col-md-4">
                        <div className="">
                            <img src={images[selectedSwitch]} alt="Flat Copper Submersible Cables" className="switch-img" width="100%" />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="cables__main--title">Flat Copper Submersible Cables</div>
                        <div className="">
                            <p className="wires__p"><CgChevronDoubleRight color="#fe8a0f" /><span className="advantages__span">Cable Sheath:</span> The outer sheath of the cable is made from a special grade of abrasion resistant pvc compound impervious to water, grease, oil etc.</p>
                            <p className="wires__p"><CgChevronDoubleRight color="#fe8a0f" /><span className="advantages__span">Conductor:</span> The conductors are drawn from 99.97% bright electrolytic grade copper with more than 100% conductivity which are annealed and bunched together.</p>
                            <p className="wires__p wires__list--wrapper"><CgChevronDoubleRight color="#fe8a0f" /><span className="advantages__span">Insulation:</span>
                                <ul>
                                    <li>The bunch conductors are insulated with specially formulated pvc compound with a high insulation resistance value.</li>
                                    <li>
                                        The insulation process is carried out on modern high speed extrusion lines, which ensures high accuracy and consistency in performance.
                                    </li>
                                </ul>
                            </p>
                            <div className="d-flex align-items-center" style={{ gap: "10px" }}>
                                <img src={images1[selectedSwitch]} alt="" className="fcsubinside__img" />
                                <div className="switch__btn--main_wrapper">
                                    <div className="switch__btn__wrapper_3" onClick={() => setSelectedSwitch(0)}>
                                        <div className={`switch__btn_3 ${selectedSwitch === 1 ? 'active' : ''}`}></div>
                                    </div>
                                    <div className="switch__btn__wrapper" onClick={() => setSelectedSwitch(1)}>
                                        <div className={`switch__btn ${selectedSwitch === 0 ? 'active' : ''}`}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DownloadCatalogue />
                <div className="fc__tabs-wrapper">
                    <div className="tabs">
                        <ul className="tab-list">
                            {Object.keys(tabData).map(tab => (
                                <TabCom
                                    key={tab}
                                    label={tab}
                                    onClick={() => setActiveTab(tab)}
                                    isActive={activeTab === tab}
                                />
                            ))}
                        </ul>
                        <TabContent content={tabData[activeTab]} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default FCSubmersiblecable;