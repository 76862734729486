import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useLocation } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../../assets/images/Logo.png";
import Offcanvas from 'react-bootstrap/Offcanvas';
import './header.css';
import { IoChevronDown } from "react-icons/io5";
import { IoChevronForward } from "react-icons/io5";

function Header() {
    const [scrolling, setScrolling] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            const isTop = window.scrollY < 100;
            if (isTop !== scrolling) {
                setScrolling(isTop);
            }
        };

        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [scrolling]);

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [isChevronRotated, setChevronRotated] = useState(false);
    const [isSubSubMenuOpen, setIsSubSubMenuOpen] = useState(false);
    const [isChRotated, setChRotated] = useState(false);
    const [isCablesMenuOpen, setIsCablesMenuOpen] = useState(false);
    const [isCheRotated, setCheRotated] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSubMenu = (e) => {
        if (isMobile) {
            e.preventDefault();
            setIsSubMenuOpen(!isSubMenuOpen);
            setChevronRotated(!isChevronRotated);

            // Close other submenus
            if (!isSubMenuOpen) {
                setIsSubSubMenuOpen(false);
                setChRotated(false);
                setIsCablesMenuOpen(false);
                setCheRotated(false);
            }
        }
    };

    const toggleSubSubMenu = (e) => {
        if (isMobile) {
            e.preventDefault();
            setIsSubSubMenuOpen(!isSubSubMenuOpen);
            setChRotated(!isChRotated);

            // Close other submenus
            if (!isSubSubMenuOpen) {
                setIsCablesMenuOpen(false);
                setCheRotated(false);
            }
        }
    };

    const toggleCableSubMenu = (e) => {
        if (isMobile) {
            e.preventDefault();
            setIsCablesMenuOpen(!isCablesMenuOpen);
            setCheRotated(!isCheRotated);

            // Close other submenus
            if (!isCablesMenuOpen) {
                setIsSubSubMenuOpen(false);
                setChRotated(false);
            }
        }
    };

    // Define active states for menu items
    const isHomePage = location.pathname === '/';
    const isProductPage = location.pathname === '/products';
    const isCertiPage = location.pathname === '/certificate';
    const isaboutPage = location.pathname === '/aboutus';
    const isCareerPage = location.pathname === '/career';
    const isInquiryPage = location.pathname === '/inquiry';
    const isContactPage = location.pathname === '/contact';

    // Define active states for submenu items
    const isActiveProduct = isProductPage || location.pathname.startsWith('/products/');
    const isActiveCables = location.pathname.startsWith('/products/cables/');
    const isActiveWires = location.pathname.startsWith('/products/wires/');

    return (
        <Navbar collapseOnSelect expand="lg" className={`ctm__header ${scrolling ? '' : 'scroll_sticky_box-shadow'}`} sticky='top'>
            <Container>
                <Navbar.Brand href="/">
                    <img src={logo} alt="logo" className='header__logo' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-lg`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                            Offcanvas
                        </Offcanvas.Title> */}
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <nav className="ms-auto">
                            <ul className="menu">
                                <li className={isHomePage ? 'home-page-menu' : ''}><a href="/" className='menu__a'>Home</a></li>
                                <li className={isActiveProduct ? 'active' : ''}>
                                    <a href="/products" className={`menu__a ${isActiveProduct ? 'active' : ''}`}>
                                        Our Products <IoChevronDown
                                            className={isChevronRotated ? 'chevron-rotated' : ''}
                                            onClick={toggleSubMenu}
                                        />
                                    </a>
                                    <ul className={`submenu ${isSubMenuOpen ? 'submenu-open' : ''}`}>
                                        <li>
                                            <a href="/products/wires" className={isActiveWires ? 'active' : ''} onClick={toggleSubSubMenu}>
                                                Wires <IoChevronForward className={isChRotated ? 'ch-rotate' : ''} />
                                            </a>
                                            <ul className={`submenu ${isSubSubMenuOpen ? 'submenu-open' : ''}`}>
                                                <li className='wires_Submenu'><a href="/products/wires">House Wires</a></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="" className={isActiveCables ? 'active' : ''} onClick={toggleCableSubMenu}>
                                                Cables <IoChevronForward className={isCheRotated ? 'che-rotate' : ''} />
                                            </a>
                                            <ul className={`submenu ${isCablesMenuOpen ? 'submenu-open' : ''}`}>
                                                <li className='cables_submenu'><a href="/products/cables/flat-copper-submersible-cable">Flat Copper Submersible Cables</a></li>
                                                <li className='cables_submenu'><a href="/products/cables/copper-round-two-core-cable">Copper Round Two Core Cables</a></li>
                                                <li className='cables_submenu'><a href="/products/cables/copper-round-three-core-cable">Copper Round Three Core Cables</a></li>
                                                <li className='cables_submenu'><a href="/products/cables/copper-round-four-core-cable">Copper Round Four Core Cables</a></li>
                                                <li className='cables_submenu'><a href="/products/cables/round-aluminium-two-core-cable">Flat Aluminum Two Core Cables</a></li>
                                                <li className='cables_submenu'><a href="/products/cables/round-aluminium-three-core-cable">Round Aluminum Three Core Cables</a></li>
                                                <li className='cables_submenu'><a href="/products/cables/round-aluminium-four-core-cable">Round Aluminum Four Core Cables</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className={isCertiPage ? 'home-page-menu' : ''}><a href="/certificate" className='menu__a'>Certifications</a></li>
                                <li className={isaboutPage ? 'home-page-menu' : ''}><a href="/aboutus" className='menu__a'>About Us</a></li>
                                <li className={isCareerPage ? 'home-page-menu' : ''}><a href="/career" className='menu__a'>Careers</a></li>
                                <li className={isInquiryPage ? 'home-page-menu' : ''}><a href="/inquiry" className='menu__a'>Inquiry</a></li>
                                <li className={isContactPage ? 'home-page-menu' : ''}><a href="/contact" className='menu__a'>Contact Us</a></li>
                            </ul>
                        </nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
                {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <nav className="ms-auto">
                        <ul className="menu">
                            <li className={`${isHomePage ? 'home-page-menu' : ''}`}><a href="/" className='menu__a'>Home</a></li>
                            <li className={`${isActiveProduct ? 'active' : ''}`}>
                                <a href="/products" className={`menu__a ${isActiveProduct ? 'active' : ''}`}>Our Products <IoChevronDown /></a>
                                <ul className="submenu">
                                    <li>
                                        <a href="/products/wires" className={`${isActiveWires ? 'active' : ''}`}>Wires <IoChevronForward /></a>
                                        <ul className="submenu">
                                            <li><a href="/products/wires">House Wires</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="/products/cables" className={`${isActiveCables ? 'active' : ''}`}>Cables <IoChevronForward /></a>
                                        <ul className="submenu">
                                            <li><a href="/products/cables/flat-copper-submersible-cable">Flat Copper Submersible Cables</a></li>
                                            <li><a href="/products/cables/copper-round-two-core-cable">Copper Round Two Core Cables</a></li>
                                            <li><a href="/products/cables/copper-round-three-core-cable">Copper Round Three Core Cables</a></li>
                                            <li><a href="/products/cables/copper-round-four-core-cable">Copper Round Four Core Cables</a></li>
                                            <li><a href="/products/cables/round-aluminium-two-core-cable">Round Aluminum Two Core Cables</a></li>
                                            <li><a href="/products/cables/round-aluminium-three-core-cable">Round Aluminum Three Core Cables</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className={`${isCertiPage ? 'home-page-menu' : ''}`}><a href="/certificate" className='menu__a'>Certifications</a></li>
                            <li className={`${isaboutPage ? 'home-page-menu' : ''}`}><a href="/aboutus" className='menu__a'>About Us</a></li>
                            <li className={`${isCareerPage ? 'home-page-menu' : ''}`}><a href="/career" className='menu__a'>Careers</a></li>
                            <li className={`${isInquiryPage ? 'home-page-menu' : ''}`}><a href="/inquiry" className='menu__a'>Inquiry</a></li>
                            <li className={`${isContactPage ? 'home-page-menu' : ''}`}><a href="/contact" className='menu__a'>Contact Us</a></li>
                        </ul>
                    </nav>
                </Navbar.Collapse> */}
            </Container>
        </Navbar>
    );
}

export default Header;
