import React from "react";
import { Fade } from "react-awesome-reveal";
import w_to_skycore from "../../assets/images/w_to_skycore_image.png";
import image1 from "../../assets/images/authorized/1.jpg";
import image2 from "../../assets/images/authorized/2.jpg";
import image3 from "../../assets/images/authorized/3.jpg";
import './homeaboutus.css';

const HomeAboutus = ({ title, reabtn, authorized }) => {
    return (
        <div className="container homeaboutus__main-wrapper">
            <div className="row align-items-center" style={{rowGap:'30px'}}>
                <div className="col-lg-5 col-md-6">
                    <Fade cascade damping={0.1} direction="left" triggerOnce={true}>
                        <img src={w_to_skycore} alt="aboutus__image" width="100%" />
                    </Fade>
                </div>
                <div className="col-lg-7 col-md-6">
                    <Fade direction="right" triggerOnce={true}>
                        <div className="homeaboutus__title">{title}</div>
                        <div className="homeaboutus__des">Skycore Wires And Cables Established In 2014, Specializes In Manufacturing High Quality
                            House Wires, Industrial And Agricultural Cables As Well As Power Transmission Cables. <br /><br />
                            Skycore Ensures Superior Quality And Effort Of Constant Improvement. It is Renowned For
                            Reliability And Innovation. Company’s Commitment Towards Excellence Has Made It A
                            Trusted Name In Industry. <br /><br />
                            Quality Products, Timely Production And Competitive Rates Have Been Its Ultimate Aim For
                            Customer Satisfaction. <br /><br />
                            Quality, Safety And Reliability Are Our Priorites For Business.
                        </div>
                        {reabtn && (
                            <a href="/aboutus">
                                <button className="homeaboutus__btn">
                                    <span>Continue Reading</span>
                                </button>
                            </a>
                        )}
                        {authorized && (
                            <div>
                                <img src={image1} alt='image1' height={100} />
                                <img src={image2} alt='image2' height={100} />
                                <img src={image3} alt='image3' height={100} />
                            </div>
                        )}
                    </Fade>
                </div>
            </div>
        </div>
    );
}

export default HomeAboutus;