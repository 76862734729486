import React from "react";
import ProductsCom from "../components/OurProducts/Products";
import HouseWires from "../components/OurProducts/HouseWires";
import AgriculturalandIndustrial from "../components/OurProducts/AgricultureandInduCables";

const Products = () => {
    return(
        <>
            <ProductsCom />
            <HouseWires />
            <AgriculturalandIndustrial />
        </>
    )
}

export default Products