import React, { useState } from "react";
import BreadcrbCom from "../Breadcrumb/BreadcrubCom";
import ce_logo from "../../assets/images/authorized/2.jpg";
import ce from "../../assets/images/authorized/ce.jpg";
import iso_logo from "../../assets/images/authorized/9001_2015.png";
import iso from "../../assets/images/authorized/iso.jpg";
import './certificate.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal({ show, onHide, image, title }) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable={true}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src={image} alt={title} width="100%" height="80%" />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} className="certifictate_close_btn">Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

const Certificate = () => {
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const handleOpenModal = (image, title) => {
        setModalContent({ image, title });
        setModalShow(true);
    };

    return (
        <>
            <div className="certificate_bg_wrapper">
                <BreadcrbCom link1="/" link1text="Home" active="Certifications" title="Certifications" />
            </div>
            <div className="container certificate__main-wrapper">
                <div className="row justify-content-center">
                    <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 text-center">
                        <div className="certificate__wrappper">
                            <img src={ce_logo} height={180} />
                            <Button className="certificate_btn" variant="primary" onClick={() => handleOpenModal(ce, "Certificate of Compliance")}>
                                Certificate of Compliance
                            </Button>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 text-center">
                        <div className="certificate__wrappper">
                            <img src={iso_logo} height={180} className="iso__log0" />
                            <Button className="certificate_btn" variant="primary" onClick={() => handleOpenModal(iso, "ISO 9001:2015")}>
                                ISO 9001:2015
                            </Button>
                        </div>
                    </div>
                </div>
                <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    image={modalContent.image}
                    title={modalContent.title}
                />
            </div>
        </>
    );
}

export default Certificate;
