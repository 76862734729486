import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../Contact/contact.css';
import './career.css';
import { Accordion } from 'react-bootstrap';
import BreadcrbCom from '../Breadcrumb/BreadcrubCom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebaseConfig';
import Select from 'react-select';

const uploadFileToFirebase = async (file) => {
    const storageRef = ref(storage, `resumes/${file.name}`);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
};

function MyVerticallyCenteredModal(props) {
    const formRef = useRef();

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            if (values.resume && values.resume.size > 5 * 1024 * 1024) {
                throw new Error('File size exceeds 5MB limit');
            }

            const resumeUrl = await uploadFileToFirebase(values.resume);

            const formData = {
                name: values.name,
                email: values.email,
                contact: values.contact,
                city: values.city,
                state: values.state,
                subject: values.subject,
                resume: resumeUrl
            };

            await emailjs.send(
                'service_w60d2km', // Replace with your service ID
                'template_pgaorpq', // Replace with your template ID
                formData,
                'C2L6AVUOAvbR3GVKl' // Replace with your public key
            );
            // console.log("formta:", formData)
            alert('Form submitted successfully!');
            resetForm({
                values: {
                    name: '',
                    email: '',
                    contact: '',
                    city: '',
                    state: '',
                    subject: '',
                    resume: null
                }
            });
            setSubmitting(false);
        } catch (error) {
            console.error('Error submitting form:', error.message || error);
            if (error.message === 'File size exceeds 5MB limit') {
                alert('File size exceeds 5MB limit. Please upload a smaller file.');
            } else {
                alert('Failed to submit form. Please try again later.');
            }
            setSubmitting(false);
        }
    };

    const subjectOptions = [
        { value: 'Marketing', label: 'Marketing' },
        { value: 'Accounts', label: 'Accounts' },
        { value: 'Human Resources(HR)', label: 'Human Resource(HR)' },
        { value: 'Quality Control', label: 'Quality Control' },
        { value: 'Production', label: 'Production' },
    ];

    const defaultSubject = { value: 'Marketing', label: 'Marketing' }; // Default value

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'var(--primary-color-50)',
            border: "1px solid var(--primary-color-50)",
            boxShadow: 'none',
            borderRadius: '4px',
            padding: '6.2px 10px',
            width: '100%',
            margin: '0px',
            boxSizing: 'border-box',
            '&:hover': {
                borderColor: 'none'
            },
            '&:focus': {
                borderColor: 'none'
            },
            '&:focus-visible': {
                borderColor: 'none'
            }
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--primary-color)' : 'var(--primary-color-50)',
            color: state.isFocused ? 'var(--white-color)' : 'var(--black-color)',
            padding: 10,
            zIndex: 9999
        })
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable={true}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        contact: '',
                        city: '',
                        state: '',
                        subject: defaultSubject.value,
                        resume: null
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required('First Name is Required'),
                        email: Yup.string().email('Invalid email address').required('Email is Required'),
                        contact: Yup.string()
                            .required('Contact Number is Required')
                            .matches(/^[0-9]+$/, "Contact Number must be digits only"),
                        city: Yup.string().required('City is Required'),
                        state: Yup.string().required('State is Required'),
                        subject: Yup.string().required('Job Title is Required'),
                        resume: Yup.mixed()
                            .required('Resume is Required')
                            .test('fileSize', 'File size is too large', value => value && value.size <= 5 * 1024 * 1024) // 5MB limit
                            .test('fileType', 'Unsupported file format', value => value && ['application/pdf'].includes(value.type))
                    })}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form ref={formRef}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="career_form__filled-wrapper">
                                        <label htmlFor="name" className="mb-2">Full Name *</label>
                                        <Field id="name" type="text" name="name" className="contact--ctm__input" placeholder="Full Name" />
                                        <ErrorMessage name="name" component="div" className='error_message' />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="career_form__filled-wrapper">
                                        <label htmlFor="email" className="mb-2">Email *</label>
                                        <Field id="email" type="email" name="email" className="contact--ctm__input" placeholder="Email" />
                                        <ErrorMessage name="email" component="div" className='error_message' />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="career_form__filled-wrapper">
                                        <label htmlFor="contact" className="mb-2">Phone *</label>
                                        <Field id="contact" type="text" name="contact" className="contact--ctm__input" placeholder="Phone" />
                                        <ErrorMessage name="contact" component="div" className='error_message' />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="career_form__filled-wrapper">
                                        <label htmlFor="city" className="mb-2">City *</label>
                                        <Field id="city" type="text" name="city" className="contact--ctm__input" placeholder="City" />
                                        <ErrorMessage name="city" component="div" className='error_message' />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="career_form__filled-wrapper">
                                        <label htmlFor="state" className="mb-2">State *</label>
                                        <Field id="state" type="text" name="state" className="contact--ctm__input" placeholder="State" />
                                        <ErrorMessage name="state" component="div" className='error_message' />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="career_form__filled-wrapper">
                                        <label htmlFor="subject" className="mb-2">Job Title *</label>
                                        <Select
                                            options={subjectOptions}
                                            name="subject"
                                            className="contact--ctm__select"
                                            defaultValue={defaultSubject}
                                            styles={customStyles}
                                            onChange={option => setFieldValue('subject', option.value)}
                                        />
                                        <ErrorMessage name="subject" component="div" className='error_message' />
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className="career_form__filled-wrapper">
                                        <label htmlFor="resume" className="mb-2">Upload Resume *</label>
                                        <input
                                            id="resume"
                                            name="resume"
                                            type="file"
                                            className="contact--ctm__input"
                                            onChange={(event) => {
                                                setFieldValue("resume", event.currentTarget.files[0]);
                                            }}
                                        />
                                        <ErrorMessage name="resume" component="div" className='error_message' />
                                    </div>
                                </div>
                            </div>
                            <button type="submit" disabled={isSubmitting} className='mt-3 apply_now_btn homeaboutus__btn'>
                                Apply Now
                            </button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}
const Career = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState('');

    const openModal = (title) => {
        setModalTitle(title);
        setModalShow(true);
    };

    return (
        <>
            <div className="about_bg_wrapper">
                <BreadcrbCom link1="/" link1text="Home" active="Careers" title="Careers" />
            </div>
            <div className="career__wrapper container mt-5 mb-5">
                <Accordion defaultActiveKey="0" alwaysOpen>
                    <div className="row" style={{ rowGap: '30px' }}>
                        <div className="col-md-6">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Marketing</Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        We require young and energetic marketing personnel who can market our product nationwide. Only experienced candidates may apply. Please send us your latest resume with a passport-sized photograph.
                                    </p>
                                    <div className="pt-2"><strong>LOCATION:</strong> ANY</div>
                                    <div className="pt-2"><strong>QUALIFICATION:</strong> BACHELOR/MASTER DEGREE</div>
                                    <Button variant="primary mt-2 apply-now_btn" onClick={() => openModal("Apply for Marketing")}>
                                        Apply Now
                                    </Button>

                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        title={modalTitle}
                                        onHide={() => setModalShow(false)}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                        <div className="col-md-6">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Accounts</Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        We require young and energetic accounts personnel who possess in-depth accounting knowledge and are familiar with current rules and regulations. Only experienced candidates can apply. Send us your latest resume with a passport-size photograph.
                                    </p>
                                    <div className="pt-2"><strong>LOCATION:</strong> FACTORY</div>
                                    <div className="pt-2"><strong>QUALIFICATION:</strong> BACHELOR/MASTER DEGREE</div>
                                    <Button variant="primary mt-2 apply-now_btn" onClick={() => openModal("Apply for Accounts")}>
                                        Apply Now
                                    </Button>

                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        title={modalTitle}
                                        onHide={() => setModalShow(false)}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                        <div className="col-md-6">
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Human Resource (HR)</Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        We require young and energetic HR personnel who can seamlessly handle all human resources tasks. Should possess knowledge of the current Factory Act and/or ESI schemes. Freshers can also apply. Send us your latest resume with a passport-size photograph.                                    </p>
                                    <div className="pt-2"><strong>LOCATION:</strong> FACTORY</div>
                                    <div className="pt-2"><strong>QUALIFICATION:</strong> BACHELOR/MASTER DEGREE</div>
                                    <Button variant="primary mt-2 apply-now_btn" onClick={() => openModal("Apply for Human Resource (HR)")}>
                                        Apply Now
                                    </Button>

                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        title={modalTitle}
                                        onHide={() => setModalShow(false)}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                        <div className="col-md-6">
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Quality Control</Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        We require young and energetic quality control personnel who can keep eye on stringent quality control to assure best quality finish goods. Only experienced can apply. Send us your latest resume with passport size photograph.
                                    </p>
                                    <div className="pt-2"><strong>LOCATION:</strong> FACTORY</div>
                                    <div className="pt-2"><strong>QUALIFICATION:</strong>  B.E / B.TECH</div>
                                    <Button variant="primary mt-2 apply-now_btn" onClick={() => openModal("Apply for Quality Control")}>
                                        Apply Now
                                    </Button>

                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        title={modalTitle}
                                        onHide={() => setModalShow(false)}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                        <div className="col-md-6">
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Production</Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        We require young and energetic personnel for production process who can smoothly carry out product process independently. Only experienced can apply. Send us your latest resume with passport size photograph.
                                    </p>
                                    <div className="pt-2"><strong>LOCATION:</strong> FACTORY</div>
                                    <div className="pt-2"><strong>QUALIFICATION:</strong>  B.E / B.TECH</div>
                                    <Button variant="primary mt-2 apply-now_btn" onClick={() => openModal("Apply for Production")}>
                                        Apply Now
                                    </Button>

                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        title={modalTitle}
                                        onHide={() => setModalShow(false)}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                    </div>
                </Accordion>
            </div>
        </>
    )
}

export default Career;
