import React from "react";
import BreadcrbCom from "../Breadcrumb/BreadcrubCom";

const ProductsCom = () => {
    return (
        <>
            <div className="product_bg_wrapper">
                <BreadcrbCom link1="/" link1text="Home" active="Our Products" title="Our Products" />
            </div>
        </>
    )
}

export default ProductsCom