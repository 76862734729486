import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBWOYYeivnhqnYgvTwR2tuX9swtr30aywE",
  authDomain: "skycore-eba30.firebaseapp.com",
  projectId: "skycore-eba30",
  storageBucket: "skycore-eba30.appspot.com",
  messagingSenderId: "423448704890",
  appId: "1:423448704890:web:3a220a094cd357e1ae88c3",
  measurementId: "G-BGD6Z7MC2J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };