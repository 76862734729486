import React, { useRef } from "react";
import emailjs from '@emailjs/browser';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Select from 'react-select';
import './contact.css';

const GetInTouchForm = ({ title }) => {
    const formRef = useRef();

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const formData = {
                name: values.name,
                email: values.email,
                contact: values.contact,
                city: values.city,
                state: values.state,
                subject: values.subject,
                message: values.message
            };

            await emailjs.send(
                'service_w60d2km', // Replace with your service ID
                'template_s48l6n5', // Replace with your template ID
                formData,
                'C2L6AVUOAvbR3GVKl' // Replace with your public key
            );
            // console.log("formta:", formData)
            alert('Form submitted successfully!');
            resetForm({
                values: {
                    name: '',
                    email: '',
                    contact: '',
                    city: '',
                    state: '',
                    subject: '',
                    message:''
                }
            });
            setSubmitting(false);
        } catch (error) {
            console.error('Error submitting form:', error.message || error);
            if (error.message === 'File size exceeds 5MB limit') {
                alert('File size exceeds 5MB limit. Please upload a smaller file.');
            } else {
                alert('Failed to submit form. Please try again later.');
            }
            setSubmitting(false);
        }
    };

    const subjectOptions = [
        { value: 'Dealership', label: 'Dealership' },
        { value: 'Job(Career)', label: 'Job(Career)' },
        { value: 'Other', label: 'Other' }
    ];

    const defaultSubject = { value: 'Dealership', label: 'Dealership' }; // Default value

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'var(--primary-color-50)',
            border: "1px solid var(--primary-color-50)",
            boxShadow: 'none',
            borderRadius: '4px',
            padding: '8.2px 10px',
            width: '100%',
            boxSizing: 'border-box',
            '&:hover': {
                borderColor: 'none'
            },
            '&:focus': {
                borderColor: 'none'
            },
            '&:focus-visible': {
                borderColor: 'none'
            }
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--primary-color)' : 'var(--primary-color-50)',
            color: state.isFocused ? 'var(--white-color)' : 'var(--black-color)',
            padding: 10
        })
    };

    return (
        <div className='contactform__wrpper'>
            <div className='getintouch_title'>{title}</div>
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    contact: '',
                    city: '',
                    state: '',
                    subject: defaultSubject.value,
                    message: ''
                }}
                validationSchema={Yup.object({
                    name: Yup.string().required('Full name is Required'),
                    email: Yup.string().email('Invalid email address').required('Email is Required'),
                    contact: Yup.string().required('Phone No. is Required'),
                    city: Yup.string().required('City isRequired'),
                    state: Yup.string().required('State is Required'),
                    subject: Yup.string().required('Subject is Required'),
                    message: Yup.string().required('Message is Required')
                })}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form ref={formRef}>
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-10 get_in--touchform">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form__filled-wrapper">
                                            <Field id="name" type="text" name="name" className="contact--ctm__input" placeholder="Full Name" />
                                            <ErrorMessage name="name" component="div" className='error_message' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form__filled-wrapper">
                                            <Field id="email" type="email" name="email" className="contact--ctm__input" placeholder="Email" />
                                            <ErrorMessage name="email" component="div" className='error_message' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form__filled-wrapper">
                                            <Field id="contact" type="text" name="contact" className="contact--ctm__input" placeholder="Phone" />
                                            <ErrorMessage name="contact" component="div" className='error_message' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form__filled-wrapper">
                                            <Field id="city" type="text" name="city" className="contact--ctm__input" placeholder="City" />
                                            <ErrorMessage name="city" component="div" className='error_message' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form__filled-wrapper">
                                            <Field id="state" type="text" name="state" className="contact--ctm__input" placeholder="State" />
                                            <ErrorMessage name="state" component="div" className='error_message' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form__filled-wrapper">
                                            <Select
                                                options={subjectOptions}
                                                name="subject"
                                                className="contact--ctm__select"
                                                defaultValue={defaultSubject}
                                                styles={customStyles}
                                                onChange={option => setFieldValue('subject', option.value)}
                                            />
                                            <ErrorMessage name="subject" component="div" className='error_message' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form__filled-wrapper">
                                            <Field as="textarea" id="message" name="message" rows="4" cols="50" className="contact--ctm__input" placeholder="Message" />
                                            <ErrorMessage name="message" component="div" className='error_message' />
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" disabled={isSubmitting} className='connect_btn mt-3'>
                                    Let's Connect!
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default GetInTouchForm;
