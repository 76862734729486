import React from "react";

const DownloadCatalogue = () => {
    return (
        <div class="" style={{ display: "flex", alignItems: 'center' }}>
            <div class="line"></div>
            <a href="/catalogue.pdf" download>
                <button onclick="" style={{
                    backgroundColor: 'var(--primary-color)',
                    padding: '10px 20px',
                    border: 'none',
                    color: 'var(--white-color)',
                    borderRadius: '8px',
                    margin: '10px'
                }} class="download_catalogue_btn">Download Catalogue</button>
            </a>
            <div class="line"></div>
        </div>
    )
}

export default DownloadCatalogue