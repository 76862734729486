import React from "react";
import FCSubmersiblecable from "../components/Cables/FCSubmersiblecable";

const FlatCopperSubmersibleCables = () => {
    return (
        <>
            <FCSubmersiblecable />
        </>
    )
}

export default FlatCopperSubmersibleCables;