import React from "react";
import { Slide } from "react-awesome-reveal";
import './hrwires.css';
import { HouseWiresData } from "./ProductsData";
import { useNavigate } from "react-router-dom";

const HouseWires = () => {
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path);
    };

    return (
        <>
            <div className="container hrwires_main-wrapper">
                <div className='hrwires_title'>House Wires</div>
                <Slide direction="left" triggerOnce={true}>
                    <div className="row">
                        {
                            HouseWiresData.map((data) => {
                                return (
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 position-relative">
                                        <div className="house_wires_img--wrapper" onClick={() => handleClick(data?.path)}>
                                            <img src={data.img} alt="house_Wires" className="house_wires_img" />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Slide>
            </div>
        </>
    )
}

export default HouseWires