import React from "react";
import { Zoom } from "react-awesome-reveal";
import { MdVisibility } from "react-icons/md";
import { FaTelegramPlane } from "react-icons/fa";
import { FaCrosshairs } from "react-icons/fa6";

const MissionVision = () => {
    return (
        <>
            {/* <section className="py-0 about-us about__us__mv">
                <div className="container-fluid">
                    <div className="row no-gutters">

                        <div className="col-12 col-lg-4 image-hover-style-3 h-100">
                            <div className="feature-box-wrap">
                                <div className="w-100 d-table position-relative cover-background small-screen mv_image_1"></div>
                                <div className="w-100 small-screen d-table arrow-top">
                                    <div className="d-lg-table-cell align-middle padding-eighteen-lr text-center">
                                        <MdVisibility fontSize={40} color="#ed6402" className="mb-3" />
                                        <h4 className="text-uppercase mb-2">Our Vision</h4>
                                        <p className="mx-auto">To build a world-className organization through reliability in the field of our expertise and provide a <a href="life-at-dynamic.html" target="_blank">great place to work</a> together with high business values of trust. To be a most preferred supplier with the feeling of perfection in the field related to our Industry for the welfare of the Trade, People, State &amp; Nation.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4 image-hover-style-3 height-100">
                            <div className="feature-box-wrap md-swap-block">
                                <div className="w-100 small-screen d-table arrow-bottom">
                                    <div className="d-lg-table-cell align-middle padding-eighteen-lr text-center">
                                        <FaTelegramPlane fontSize={40} color="#ed6402" className="mb-3" />
                                        <h4 className="text-uppercase mb-2">Our Mission</h4>
                                        <p>With an increasing overseas footprint and a new plant in offing, the company is targeting <strong>Rs. 1500-2000 Cr.</strong> topline in ten years with an objective to keep pace with changing technology, manufacturing process, quality controls, environment and safety requirements, timely delivery, after sales service, customer satisfaction and winning customer confidence and trust. </p>
                                    </div>
                                </div>
                                <div className="w-100 d-table position-relative cover-background small-screen  mv_image_2"></div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4 image-hover-style-3 height-100">
                            <div className="feature-box-wrap">
                                <div className="w-100 d-table position-relative cover-background small-screen  mv_image_3"></div>
                                <div className="w-100 small-screen d-table arrow-top">
                                    <div className="d-lg-table-cell align-middle padding-eighteen-lr text-center">
                                        <FaCrosshairs fontSize={40} color="#ed6402" className="mb-3" />
                                        <h4 className="text-uppercase mb-2">Our Strategy</h4>
                                        <p className="mx-auto mb-0">Dynamic Cables strategy for long-term growth is through cost rationalization with efficient improvements and innovations, targeting investments across growth markets, enriching the product portfolio and enhancing brand visibility to leverage more opportunities in various sectors.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="about_us__mv-new">
                <Zoom direction="in" triggerOnce={true}>
                    <div className="container about_us-new__mv__deswrapper">
                        <div className="row ab_mv_row">
                            <div className="col-md-2 mv_col">
                                <MdVisibility fontSize={40} color="#ed6402" className="mb-3" />
                            </div>
                            <div className="col-md-10">
                                <div className="about_us_mv_title-new">
                                    Vision
                                </div>
                                <div className="about_us_mv_des-new">
                                    At Skycore, we aim to be among leading cable manufacturers in the world. We aim to make our brand synonymous with impeccable quality and strive to achieve customer satisfaction with technical innovation and customized solutions.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 mv_col">
                                <FaTelegramPlane fontSize={40} color="#ed6402" className="mb-3" />
                            </div>
                            <div className="col-md-10">
                                <div className="about_us_mv_title-new">
                                    Mission
                                </div>
                                <div className="about_us_mv_des-new">
                                    At Skycore, we believe that the company will prosper when all the stakeholders’ interests are aligned.
                                    <ul>
                                        <li>Clients - We are focused on innovation both in our products and services to our clients. Improving, refining and reviewing our products on a regular level helps us to have a competitive edge.</li>

                                        <li>Employees - We believe in fostering a work environment that is built on respect, trust and entrepreneurship. The focus on quality is ingrained in each and every employee of the organization.</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Zoom>
            </section>
        </>
    )
}

export default MissionVision;