import React from "react";
import BreadcrbCom from "../Breadcrumb/BreadcrubCom";
import GetInTouchForm from "../Contact/GetInTouchForm";

const Inquiry = () => {
    return (
        <>
            <div className="about_bg_wrapper">
                <BreadcrbCom link1="/" link1text="Home" active="Inquiry" title="Inquiry" />
            </div>
            <div className="container">
                <GetInTouchForm title="Make an Inquiry" />
            </div>
        </>
    )
}

export default Inquiry