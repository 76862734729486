import React from "react";
import Header from "../components/Header/header";
import HeroSlider from "../components/HeroSlider/HeroSlider";
import HomeAboutus from "../components/HomeAbout/HomeAboutus";
import Counter from "../components/Counter/Counter";
import AuthorizedBy from "../components/Authorized/AuthorizedBy";
import OurProducts from "../components/OurProducts/OurProucts";
import TrustOn from "../components/TrustOn/TrustOn";
import HouseWires from "../components/OurProducts/HouseWires";
import AgriculturalandIndustrial from "../components/OurProducts/AgricultureandInduCables";
import GroupOfCompany from "../components/Company/GroupOfCompany";

const Home = () => {
    return (
        <>
            <HeroSlider />
            {/* <OurProducts /> */}
            <HouseWires />
            <AgriculturalandIndustrial />
            <HomeAboutus title="Welcome to skycore cables & wires" reabtn={true} />
            <Counter />
            <TrustOn />
            <AuthorizedBy />
            {/* <GroupOfCompany /> */}
        </>
    )
}

export default Home