import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer__main-wrapper">
      <div className="container">
        <div className="row footer__row">
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="footer_title">Quick Link</div>
            <ul>
              <li>
                <a href="/" className="footer__quicklink--a">
                  Home
                </a>
              </li>
              <li>
                <a href="/aboutus" className="footer__quicklink--a">
                  About us
                </a>
              </li>
              <li>
                <a href="/products" className="footer__quicklink--a">
                  Our Products
                </a>
              </li>
              <li>
                <a href="/certificate" className="footer__quicklink--a">
                  Certifications
                </a>
              </li>
              <li>
                <a href="/career" className="footer__quicklink--a">
                  Careers
                </a>
              </li>
              <li>
                <a href="/inquiry" className="footer__quicklink--a">
                  Inquiry
                </a>
              </li>
              <li>
                <a href="/contact" className="footer__quicklink--a">
                  Contact us
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="footer_title">Our Products</div>
            <ul>
              <li>
                <a href="/products/wires" className="footer__quicklink--a">
                  House Wires
                </a>
              </li>
              <li>
                <a
                  href="/products/cables/flat-copper-submersible-cable"
                  className="footer__quicklink--a"
                >
                  Flat Copper Submersible Cables
                </a>
              </li>
              <li>
                <a
                  href="/products/cables/copper-round-two-core-cable"
                  className="footer__quicklink--a"
                >
                  Copper Round Two Core Cables
                </a>
              </li>
              <li>
                <a
                  href="/products/cables/copper-round-three-core-cable"
                  className="footer__quicklink--a"
                >
                  Copper Round Three Core Cables
                </a>
              </li>
              <li>
                <a
                  href="/products/cables/copper-round-four-core-cable"
                  className="footer__quicklink--a"
                >
                  Copper Round Four Core Cables
                </a>
              </li>
              <li>
                <a
                  href="/products/cables/round-aluminium-two-core-cable"
                  className="footer__quicklink--a"
                >
                  Flat Aluminum Two Core Cables
                </a>
              </li>
              <li>
                <a
                  href="/products/cables/round-aluminium-three-core-cable"
                  className="footer__quicklink--a"
                >
                  Round Aluminum Three Core Cables
                </a>
              </li>
              <li>
                <a
                  href="/products/cables/round-aluminium-four-core-cable"
                  className="footer__quicklink--a"
                >
                  Round Aluminum Four Core Cables
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="footer_title">Our Location</div>
            <ul>
              <li>
                <a
                  className="footer__quicklink--a"
                  href="https://maps.app.goo.gl/CoA9ZZSBqXVNhtog9"
                  target="_blank"
                >
                  Plot no 126/B, Survey no 128, Behind shanti dham-2, SIDC Road,
                  Veraval (shapar) Rajkot (gujarat) 360024
                </a>
              </li>
              <li>
                <a href="tel:+91 9825636110" className="footer__quicklink--a">
                  Mo: +91 9825636110
                </a>
              </li>
              <li>
              <a href="tel:+91 9305309999" className="footer__quicklink--a">
                  Mo: +91 9305309999
                </a>
                </li>
              <li>
                <a
                  href="mailto:skycore@gmail.com"
                  className="footer__quicklink--a"
                >
                  Email: skycore@gmail.com
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2613.120784113043!2d70.79401932338268!3d22.14461819105826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395835904a5f2295%3A0x389499f5a3ed3fb8!2sSIDC%20Rd%2C%20Shapar%2C%20Gujarat%20360024!5e0!3m2!1sen!2sin!4v1719382623462!5m2!1sen!2sin" width="100%" height="250" frameborder="0" style={{ border: '0' }} allowfullscreen=""></iframe> */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3695.4898789049735!2d70.79408104729542!3d22.14541722786857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395835928a79cf85%3A0xa417ec0243286f71!2s128%2C%202%2C%20SIDC%20Rd%2C%20nr.%20Shantidham%20Society%2C%20Shapar%2C%20Veraval%2C%20Gujarat%20360024!5e0!3m2!1sen!2sin!4v1720595257533!5m2!1sen!2sin"
              width="100%"
              height="310"
              frameborder="0"
              style={{ border: "0", borderRadius: "8px" }}
              allowfullscreen=""
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="copyright__footer_main-wrapper">
          <div className="copyright_text">
            Copyright © 2024 Skycore wires & cables. All Rights Reserved.
          </div>
          <div className="social__media-wrapper">
            <a href="">
              <FaFacebookF color="#ffffff" className="facebook__logo" />
            </a>
            <a href="">
              <FaInstagram
                color="#ffffff"
                className="facebook__logo instagram__logo"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
