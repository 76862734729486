import React, { useState } from "react";
import BreadcrbCom from "../Breadcrumb/BreadcrubCom";
import { TabCom, TabContent } from "./FCSubmersiblecable";
import { CgChevronDoubleRight } from "react-icons/cg";
import fcsub from "../../assets/images/Products/Flat Copper Submersible Cables 1.png";
import Housewire1 from "../../assets/images/Products/wire 1.png";
import Housewire2 from "../../assets/images/Products/wire 2.png";
import Housewire3 from "../../assets/images/Products/wire 3.png";
import Housewire4 from "../../assets/images/Products/wire 4.png";
import './fcsubmersiblecables.css';
import DownloadCatalogue from "./DownloadCatalogue";

const HouseWire = () => {
    const [activeTab, setActiveTab] = useState('Features');
    const [selectedSwitch, setSelectedSwitch] = useState(0);

    const tabData = {
        'Features': (
            <div>
                <p className="text-uppercase"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} />
                    Housing wire is designed to be flexible enough to be routed through walls, ceilings, and conduits without breaking or damaging the insulation, facilitating installation in various home configurations.                </p>
                <p className=""><CgChevronDoubleRight color="#fe8a0f" fontSize={30} />
                    MANUFACTURED FROM BRIGHT ANNEALED 99.97% PURE COPPER CONDUCTORS THESE CABLES
                    HAVE LOW CONDUCTOR RESISTANCE RESULTING IN POWER SAVING.
                </p>
            </div>
        ),
        'Applications': (
            <div>
                <p className=""><CgChevronDoubleRight color="#fe8a0f" fontSize={30} />THIS IS A PREMIUM PRODUCT OF ELECTRICAL WIRES FROM SKYCORE CABLES. THESE WIRES ARE IDEALS FOR WIRING IN RESIDENTIAL AND COMMERCIAL COMPLEXES, HIGH-RISE BUILDING, HOTELS, HOSPITALS, SCHOOL, COLLEGES ETC.THESE WIRES EMIT LOW SMOKE DURING FIRE.
                </p>
            </div>
        ),
        'Type of insulation': (
            <div>
                <p className="text-uppercase"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /><span className="advantages__span">HR  PVC COMPOUND : </span>
                    These wires are heat redundant, manufactured on high accuracy and high speed execution lines. These wires are installed at high room temperature
                </p>
                <p className="text-uppercase"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /><span className="advantages__span">FR PVC COMPOUND : </span>
                    These wires are heat Redundant, manufacturing on high accuracy and high speed execution lines. These wires are flame redundant during fire and ensuring fire safety.
                </p>
                <p className="text-uppercase"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /><span className="advantages__span">FRLS COMPOUND : </span>
                    These wire is flame REDUNDANT, also low smoke emitted wires, ensuring human safety especially during fires.
                </p>
                <p className="text-uppercase"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /><span className="advantages__span">HR FR LSH COMPOUND : </span>
                    THESE WIRES ARE FLAME REDUNDANT, HEAT REDUNDANT AND LOW SMOKY DURING FIRE.MOREOVER, THIS WIRE IS NOT TOXIC AND MINIMAL
                </p>
            </div>
        ),
        'Technical Info': (
            <>
                <table>
                    <thead>
                        <tr>
                            <th colspan="8">SIZE DIMENSIONS AND RATING</th>
                        </tr>
                        <tr>
                            <th rowspan="2">Nominal Cross Sectional Area (mm²)</th>
                            <th rowspan="2">Number / Nominal Dia. of Wire</th>
                            <th rowspan="2">Nominal Thickness of Insulation (mm)</th>
                            <th rowspan="2">Approx Overall Dia. (+/- 0.2 mm)</th>
                            <th rowspan="2">Maximum DC Resistance of Conductor at 20°C (Ohm/km)</th>
                            <th colspan="2">Maximum Current Carrying Capacity (A)</th>
                        </tr>
                        <tr>
                            <th>Casing</th>
                            <th>Concealed</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>0.5</td>
                            <td>16 / 0.2**</td>
                            <td>0.60</td>
                            <td>2.10</td>
                            <td>39.00</td>
                            <td>5.00</td>
                            <td>4.00</td>
                        </tr>
                        <tr>
                            <td>0.75</td>
                            <td>24 / 0.2**</td>
                            <td>0.60</td>
                            <td>2.30</td>
                            <td>26.00</td>
                            <td>8.00</td>
                            <td>7.00</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>14 / 0.3*</td>
                            <td>0.70</td>
                            <td>2.80</td>
                            <td>18.10</td>
                            <td>12.00</td>
                            <td>11.00</td>
                        </tr>
                        <tr>
                            <td>1.5</td>
                            <td>22 / 0.3*</td>
                            <td>0.70</td>
                            <td>3.10</td>
                            <td>12.10</td>
                            <td>16.00</td>
                            <td>13.00</td>
                        </tr>
                        <tr>
                            <td>2.5</td>
                            <td>36 / 0.3*</td>
                            <td>0.80</td>
                            <td>3.70</td>
                            <td>7.41</td>
                            <td>22.00</td>
                            <td>18.00</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>56 / 0.3**</td>
                            <td>0.80</td>
                            <td>4.10</td>
                            <td>4.95</td>
                            <td>29.00</td>
                            <td>24.00</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>84 / 0.3**</td>
                            <td>0.80</td>
                            <td>5.20</td>
                            <td>3.30</td>
                            <td>37.00</td>
                            <td>31.00</td>
                        </tr>
                        <tr><td colSpan='7'><p>* Class - 2 Flexible Copper Conductor As Per IS 8130</p></td></tr>
                        <tr><td colSpan='7'><p>** Class - 5 Flexible Copper Conductor As Per IS 8130</p></td></tr>
                    </tbody>
                </table>
            </>
        ),
    };

    const images = [Housewire1, Housewire2, Housewire3, Housewire4];

    return (
        <>
            <div className="about_bg_wrapper">
                <BreadcrbCom link1="/" link2='/products' link1text="Home" link2text="Our Products" active="Wires" title="House Wires" />
            </div>
            <div className="container fcsc__wrapper">
                <div className="row mb-5">
                    <div className="col-md-6 col-lg-4">
                        <div className="">
                            <img src={images[selectedSwitch]} alt="House Wires" width="100%" />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-8">
                        <div className="cables__main--title">House Wires</div>
                        <div style={{ marginLeft: '8px' }}>
                            <p className="wires__p"><CgChevronDoubleRight color="#fe8a0f" /><span className="advantages__span">Conductor:</span> THE CONDUCTORS DRAWN FROM 99.97 % BRIGHT ELECTROLYTIC GRADE COPPER WITH MORE THAN 100% CONDUCTIVITY. ANNEALED AND BUNCHED TOGETHER.</p>
                            {/* <br /> <br />
                            Skycore Cables are produced from best quality electrolytic copper, which is drawn, annealed and bunched on automatic machines to ensure flexibility and uniform resistance. The conductors are insulated with a special grade of PVC on sophisticated co-extrusion lines. Outer sheath consists of highly abrasion resistant PVC compound impervious to grease oil and water etc. */}
                        </div>
                        {/* <br /> */}
                        <div style={{ marginLeft: '10px' }}  >
                            <div style={{ color: '#fe8a0f' }} className="pb-2"><strong>Available In Various Grades :</strong></div>
                            <ul style={{ listStyle: 'square' }}>
                                <li>HR</li>
                                <li>FR</li>
                                <li>FRLS</li>
                                <li>HR FR LSH</li>
                            </ul>
                        </div>
                        <div style={{ color: '#fe8a0f', marginLeft: '10px' }} className="pb-2"><strong>Available In Various Grades :</strong></div>
                        <div className="switch__btn--main_wrapper" style={{ marginLeft: '10px' }}>
                            <div className="switch__btn__wrapper" onClick={() => setSelectedSwitch(0)}>
                                <div className={`switch__btn ${selectedSwitch === 0 ? 'active' : ''}`}></div>
                            </div>
                            <div className="switch__btn__wrapper_1" onClick={() => setSelectedSwitch(1)}>
                                <div className={`switch__btn_1 ${selectedSwitch === 1 ? 'active' : ''}`}></div>
                            </div>
                            <div className="switch__btn__wrapper_2" onClick={() => setSelectedSwitch(2)}>
                                <div className={`switch__btn_2 ${selectedSwitch === 2 ? 'active' : ''}`}></div>
                            </div>
                            <div className="switch__btn__wrapper_3" onClick={() => setSelectedSwitch(3)}>
                                <div className={`switch__btn_3 ${selectedSwitch === 3 ? 'active' : ''}`}></div>
                            </div>
                            <div className="switch__btn__wrapper_4">
                                <div className={`switch__btn_4`}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <DownloadCatalogue />
                <div className="fc__tabs-wrapper">
                    <div className="tabs">
                        <ul className="tab-list">
                            {Object.keys(tabData).map(tab => (
                                <TabCom
                                    key={tab}
                                    label={tab}
                                    onClick={() => setActiveTab(tab)}
                                    isActive={activeTab === tab}
                                />
                            ))}
                        </ul>
                        <TabContent content={tabData[activeTab]} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default HouseWire;
