import React, { useState } from "react";
import BreadcrbCom from "../Breadcrumb/BreadcrubCom";
import { CgChevronDoubleRight } from "react-icons/cg";
import fcsub from "../../assets/images/Products/Round Aluminum Four Core Cables box.png";
import fcsubinside from "../../assets/images/Products/Round Aluminum Four Core Cables Inside.png";
import './fcsubmersiblecables.css';
import DownloadCatalogue from "./DownloadCatalogue";

export const TabCom = ({ label, onClick, isActive }) => {
    return (
        <li className={`tab ${isActive ? 'active' : ''}`} onClick={onClick}>
            {label}
        </li>
    );
};

export const TabContent = ({ content }) => {
    return (
        <div className="tab-content">
            {content}
        </div>
    );
};


const RAFourCoreCable = () => {
    const [activeTab, setActiveTab] = useState('Advantages');

    const tabData = {
        'Advantages': (
            <div>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /><span className="advantages__span">Cost-effective : </span>Aluminum conductors are generally less expensive than copper.
                    Lightweight: Facilitates easier handling and installation, especially over long distances.
                    Corrosion Resistance: Aluminum conductors are generally resistant to corrosion, enhancing their durability.</p>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /><span className="advantages__span">Size Consideration : </span>Aluminum conductors may have larger cross-sectional areas compared to copper to achieve the same current-carrying capacity due to aluminum’s higher resistance.</p>
            </div>
        ),
        'Applications': (
            <div>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} />Power distribution in residential, commercial, and industrial buildings.</p>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} />Underground and overhead transmission lines.</p>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} />Submersible pump installations.</p>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} />Other electrical installations where aluminum’s properties such as cost-effectiveness and lightweight are advantageous.</p>
            </div>
        ),
        'Type of Insulation': (
            <div>
                <p className="wires__p wires__list--wrapper"><CgChevronDoubleRight color="#fe8a0f" /><span className="advantages__span">Insulation:</span>
                    Each conductor is individually insulated with a suitable material such as PVC (Polyvinyl Chloride), XLPE (Cross-linked Polyethylene), or another insulation material. The insulation serves several purposes:
                    <ul>
                        <li>It provides electrical insulation between the conductors, preventing short circuits and ensuring safe operation.</li>
                        <li>
                            It protects the conductors from environmental factors such as moisture, chemicals, and mechanical damage.
                        </li>
                    </ul>
                </p>
            </div>
        ),
        'Technical Info': (
            <table>
                <tr>
                    <th rowspan="2">Nominal Cross Sectional Area Of Conductor</th>
                    <th rowspan="2">Number And Nominal Dia. Of Conductor Strands </th>
                    <th rowspan="2">Thickness Of Insulation (Nominal)</th>
                    <th colspan="1">Nominal Thickness Of Sheath In mm</th>
                    <th colspan="1">Approx. Overall Dia. In mm</th>
                    <th rowspan="2">Current rating AC In Amps</th>
                    <th rowspan="2">Resistance (Max) per km. @20° C in ohms</th>
                </tr>
                <tr>
                    <th>Four Core</th>
                    <th>Four Core</th>
                </tr>
                <tr>
                    <td>4</td>
                    <td>1/2.25</td>
                    <td>0.8</td>
                    <td>1.4</td>
                    <td>12.80</td>
                    <td>19</td>
                    <td>7.41</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>1/2.76</td>
                    <td>0.8</td>
                    <td>1.4</td>
                    <td>14.00</td>
                    <td>24</td>
                    <td>4.61</td>
                </tr>
                <tr>
                    <td>10</td>
                    <td>1/3.57</td>
                    <td>1</td>
                    <td>1.6</td>
                    <td>16.50</td>
                    <td>32</td>
                    <td>3.08</td>
                </tr>
                <tr>
                    <td>16</td>
                    <td>1/4.50</td>
                    <td>1</td>
                    <td>1.6</td>
                    <td>20.50</td>
                    <td>44</td>
                    <td>1.91</td>
                </tr>
                <tr>
                    <td>25</td>
                    <td>1/5.65</td>
                    <td>1.2</td>
                    <td>1.8</td>
                    <td>23.10</td>
                    <td>49</td>
                    <td>1.20</td>
                </tr>
                <tr><td colSpan='7'><p>* Class - 2 Flexible Copper Conductor As Per IS 8130</p></td></tr>
                <tr><td colSpan='7'><p>** Class - 5 Flexible Copper Conductor As Per IS 8130</p></td></tr>
            </table>
        )
    };
    return (
        <>
            <div className="about_bg_wrapper">
                <BreadcrbCom link1="/" link2='/products' link1text="Home" link2text="Our Products" active="Cables" title="Round Aluminum Four Core Cables" />
            </div>
            <div className="container fcsc__wrapper">
                <div className="row mb-5">
                    <div className="col-md-4">
                        <div className="">
                            <img src={fcsub} alt="Round Aluminum Four Core Cables" className="" width="100%" />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="cables__main--title">Round Aluminum Four Core Cables</div>
                        <div className="">
                            <p className="wires__p"><CgChevronDoubleRight color="#fe8a0f" /><span className="advantages__span">Outer Sheath: </span> The insulated conductors are bundled together and covered with an outer sheath made of PVC, XLPE, or another suitable material. The outer sheath provides additional protection against mechanical stresses, abrasion, and environmental factors.</p>
                            <p className="wires__p"><CgChevronDoubleRight color="#fe8a0f" /><span className="advantages__span">Conductor: </span> The cable consists of Four conductors, all made of aluminum. Aluminum is chosen for its lightweight nature and relatively good conductivity, which makes it suitable for electrical transmission and distribution.</p>
                            <p className="wires__p wires__list--wrapper"><CgChevronDoubleRight color="#fe8a0f" /><span className="advantages__span">Core Arrangement: </span>
                                The Four conductors are typically arranged in a circular formation within the cable structure. This configuration helps in maintaining a compact design and facilitates easier handling during installation.                           </p>
                            <img src={fcsubinside} alt="" className="fcsubinside__img" />
                        </div>
                    </div>
                </div>
                <DownloadCatalogue />
                <div className="fc__tabs-wrapper">
                    <div className="tabs">
                        <ul className="tab-list">
                            {Object.keys(tabData).map(tab => (
                                <TabCom
                                    key={tab}
                                    label={tab}
                                    onClick={() => setActiveTab(tab)}
                                    isActive={activeTab === tab}
                                />
                            ))}
                        </ul>
                        <TabContent content={tabData[activeTab]} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default RAFourCoreCable;