import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import phone from "../../assets/images/phone icon.png";
import email from "../../assets/images/Email Icon.png";
import wp from "../../assets/images/whatsapp icon.png";
import { useNavigate } from "react-router-dom";

const ContactInfoList = () => {
    const navigate = useNavigate();

    const handleClick = (url) => {
        window.open(url, '_blank'); 
    };
    return (
        <div className="container contact__infolist--wrapper">
            <div className="row">
                {/* <div className="contact__infolist--wrapper"> */}
                <div className="col-md-6 col-lg-4">
                    <div className="contactinfolist__wrap">
                        <div className="contactinfolist__Des_wrapper">
                            <div className="contactlist__icon-wrapper">
                                <FaPhoneAlt fontSize={30}/>
                            </div>
                            <div className="contactinfolist__title">Call Us</div>
                            <div className="contactinfolist__sub-title">From Your phone</div>
                        </div>
                        <div className="contactinfolist__des"><a href="tel:+91 93053 09999" className="">+91 93053 09999</a></div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4">
                    <div className="contactinfolist__wrap">
                        <div className="contactinfolist__Des_wrapper">
                            <div className="contactlist__icon-wrapper">
                                <img src={email} alt="Phone_icon" height={30} />
                            </div>
                            <div className="contactinfolist__title">Email Us</div>
                            <div className="contactinfolist__sub-title">From Your email app</div>
                        </div>
                        <div className="contactinfolist__des"><a href="mailto:skycore@gmail.com" className="">skycore@gmail.com</a></div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4">
                    <div className="contactinfolist__wrap">
                        <div className="contactinfolist__Des_wrapper">
                            <div className="contactlist__icon-wrapper">
                                <img src={wp} alt="Phone_icon" height={35} />
                            </div>
                            <div className="contactinfolist__title">Chat Now</div>
                            <div className="contactinfolist__sub-title">Right from this website</div>
                        </div>
                        <div className="contactinfolist__des contactstart_chat"><button className="contactus__btn_start" onClick={() => handleClick('https://wa.me/9305309999')}><span>Start Chat</span><FaArrowRightLong /></button></div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </div>
    )
}

export default ContactInfoList;