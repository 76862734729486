import React from 'react';
import './authorizedby.css';
import image1 from "../../assets/images/authorized/CE.png";
import image2 from "../../assets/images/authorized/ISO.png";
import image3 from "../../assets/images/authorized/ISI.png";

const AuthorizedBy = () => {
    return (
        <div className='container authorizedbywrapper__main-wrapper'>
            <div className='authorizedby_title'>Authorized By</div>
            <div className='authorizedimg_wrappper'>
                <img src={image1} alt='image1' height={180} className='' />
                <img src={image2} alt='image1' height={180} className='' />
                <img src={image3} alt='image1' height={180} className='' />
            </div>
        </div>
    )
}

export default AuthorizedBy