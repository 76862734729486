import React from "react";
import BreadcrbCom from "../Breadcrumb/BreadcrubCom";
import './aboutus.css';
import HomeAboutus from "./HomeAboutus";
import Counter from "../Counter/Counter";
import MissionVision from "./MissionVision";
import TrustOn from "../TrustOn/TrustOn";

const AboutUs = () => {
    return (
        <>
            <div className="about_bg_wrapper">
                <BreadcrbCom link1="/" link1text="Home" active="About Us" title="About Us" />
            </div>
            <HomeAboutus title="Overview" authorized={true}/>
            {/* <Counter /> */}
            <MissionVision />
            <TrustOn />
        </>
    )
}

export default AboutUs