import React from "react";
import './hrwires.css';
import { HouseWiresData, ProductsData } from "./ProductsData";
import { useNavigate } from 'react-router-dom';

const AgriculturalandIndustrial = () => {
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path);
    };
    return (
        <>
            <div className="container aicables_main-wrapper">
                <div className='aicables_title'>Agricultural & Industrial Cables</div>
                <div className="row aicables_row">
                    {
                        ProductsData.map((data) => {
                            return (
                                <div className="col-lg-3 col-md-4 col-sm-6  position-relative" style={{ cursor: 'pointer' }} onClick={() => handleClick(data?.path)}>
                                    <div className="aicables_img--wrapper">
                                        <img src={data.img} alt="house_Wires" className="aicables__img" />
                                    </div>
                                    <div className="aicables__product-name">{data.title}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default AgriculturalandIndustrial