import React from "react";
import HouseWire from "../components/Cables/HouseWire";

const HouseWires = () => {
    return (
        <>
            <HouseWire />
        </>
    )
}

export default HouseWires;