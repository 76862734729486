import React from "react";
import './truston.css';
import TrustOnData from "./TrustOnData";
import { Slide } from "react-awesome-reveal";

const TrustOn = () => {
    return (
        <div className="truston__main-wrapper">
            <div className='truston_title'>Why you should trust skycore?</div>
            <div className="container">
                <div className="row" style={{rowGap:'65px'}}>
                    {/* <div class="truston__pro-item">
                            <div class="promo_icon">
                                <img alt="" src="https://allcabcable.com/uploads/service/why-icon01.png" />
                            </div>
                            <h3>Presence across major sectors, brands, and countries</h3>
                        </div> */}
                    {
                        TrustOnData.map((data) => {
                            return (
                                <div className="col-lg-3 col-md-6">
                                    <Slide cascade damping={0.2} direction={data.direction} triggerOnce={true}>
                                        <div className="feature__wrapper">
                                            <div class="circle">
                                                {data.img}
                                                {/* <img src={data.img} alt={data.title} className="whyshould_img"/> */}
                                            </div>
                                            <div class="rectangle">
                                                <p>{data.title}</p>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default TrustOn