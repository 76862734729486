import React from "react";
import './contact.css';
import BreadcrbCom from "../Breadcrumb/BreadcrubCom";
import GetInTouchForm from "./GetInTouchForm";
import ContactInfoList from "./ContactInfoList";

const ContactUs = () => {
    return (
        <div>
            <div className="contact_bg_wrapper">
                <BreadcrbCom link1="/" link1text="Home" active="Contact Us" title="Contact Us" />
            </div>
            <ContactInfoList />
            <div className="container contact__list_-location__wrapper">
                <div className="row justify-content-center">
                    {/* <div className="col-md-9">
                        <GetInTouchForm title="Get In Touch"/>
                    </div> */}
                    <div className="col-md-12">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2596.3759759460854!2d70.79288923217439!3d22.146102912021334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395835928a79cf85%3A0xa417ec0243286f71!2s128%2C%202%2C%20SIDC%20Rd%2C%20nr.%20Shantidham%20Society%2C%20Shapar%2C%20Veraval%2C%20Gujarat%20360024!5e0!3m2!1sen!2sin!4v1720595638402!5m2!1sen!2sin"
                            width="100%" frameborder="0" style={{ border: '0', borderRadius: '8px', margin: '50px 0px' }} allowfullscreen="" className="map_frame"></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs