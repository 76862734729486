import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import './breadcrumb.css';

function BreadcrbCom({ title, link1, link2, active, link1text, link2text, className }) {
    return (
        <div className="container breadcrumb__wrapper breadcrumb__wrapper_or">
            <h1 className='breadcrum_title'>{title}</h1>
            <Breadcrumb>
                <Breadcrumb.Item href={link1}>{link1text}</Breadcrumb.Item>
                {link2 && link2text && (
                    <Breadcrumb.Item href={link2}>
                        {link2text}
                    </Breadcrumb.Item>
                )}
                <Breadcrumb.Item active>{active}</Breadcrumb.Item>
            </Breadcrumb>
        </div>
    );
}

export default BreadcrbCom;