import React, { useState } from "react";
import BreadcrbCom from "../Breadcrumb/BreadcrubCom";
import { CgChevronDoubleRight } from "react-icons/cg";
import fcsub from "../../assets/images/Products/Copper Round Three Core Cables box.png";
import fcsubinside from "../../assets/images/Products/Copper Round Three Core Cables inside.png";
import './fcsubmersiblecables.css';
import DownloadCatalogue from "./DownloadCatalogue";

export const TabCom = ({ label, onClick, isActive }) => {
    return (
        <li className={`tab ${isActive ? 'active' : ''}`} onClick={onClick}>
            {label}
        </li>
    );
};

export const TabContent = ({ content }) => {
    return (
        <div className="tab-content">
            {content}
        </div>
    );
};


const CRThreeCoreCablee = () => {
    const [activeTab, setActiveTab] = useState('Advantages');

    const tabData = {
        'Advantages': (
            <div>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /><span className="advantages__span">High Conductivity : </span>Copper's superior electrical conductivity ensures efficient power transmission and reduced energy losses.</p>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /><span className="advantages__span">Reliability : </span>Copper conductors are robust and reliable, suitable for long-term use in diverse environments.</p>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /><span className="advantages__span">Compatibility : </span> Widely accepted and compatible with various electrical systems and equipment.</p>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /><span className="advantages__span">Standards Compliance : </span>It's essential for copper round three-core cables to comply with relevant national and international standards (e.g., IEC, BS, ASTM) to ensure safety, reliability, and compatibility with electrical installations and regulations.</p>
            </div>
        ),
        'Applications': (
            <div>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /><span className="advantages__span">Building Wiring : </span>Used for power distribution in residential, commercial, and industrial buildings.</p>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /><span className="advantages__span">Industrial Installations : </span> Suitable for machinery, equipment, and control systems requiring multiple power and signal connections.</p>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /><span className="advantages__span">Data and Communication : </span>Sometimes used in structured cabling systems where additional conductors are needed for data transmission or signaling.</p>
            </div>
        ),
        'Type of Insulation': (
            <div>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /><span className="advantages__span">Electrical Insulation : </span>It prevents the conductors from coming into direct contact with each other, thereby preventing short circuits.</p>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /><span className="advantages__span">Mechanical Protection : </span> Insulation also protects the conductors from physical damage due to abrasion, impact, or bending during installation and use.</p>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /><span className="advantages__span">Environmental Protection : </span> It shields the conductors from moisture, chemicals, and other environmental factors that could degrade the cable's performance or safety.</p>
                <p className="content_cable_wires"><CgChevronDoubleRight color="#fe8a0f" fontSize={30} /><span className="advantages__span">Core Arrangement : </span>The Three copper conductors are typically arranged in a circular formation within the cable structure. This arrangement helps maintain the cable's flexibility and ease of handling during installation.</p>

            </div>
        ),
        'Technical Info': (
            <table>
                <thead>
                    <tr>
                        <th rowspan="2">Nominal Cross Sectiona Area Of Conductor</th>
                        <th rowspan="2">Number And Nominal Dia. Of Conductor Strands</th>
                        <th rowspan="2">Thickness of Insulation (Nominal)</th>
                        <th colspan="1">Nominal Thickness Of Sheath in mm</th>
                        <th colspan="1">Approx. Overall Dia. in mm</th>
                        <th rowspan="2">Current Rating AC in Amps</th>
                        <th rowspan="2">Resistance (Max) per km. @20° C in ohms</th>
                    </tr>
                    <tr>
                        <th>Three Core</th>
                        <th>Three Core</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>0.5</td>
                        <td>16/0.20**</td>
                        <td>0.6</td>
                        <td>0.9</td>
                        <td>7.00</td>
                        <td>4</td>
                        <td>39.0</td>
                    </tr>
                    <tr>
                        <td>0.75</td>
                        <td>24/0.20**</td>
                        <td>0.6</td>
                        <td>0.9</td>
                        <td>7.30</td>
                        <td>7</td>
                        <td>26.0</td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>14/0.30*</td>
                        <td>0.7</td>
                        <td>0.9</td>
                        <td>8.20</td>
                        <td>11</td>
                        <td>18.1</td>
                    </tr>
                    <tr>
                        <td>1.5</td>
                        <td>22/0.30*</td>
                        <td>0.7</td>
                        <td>0.9</td>
                        <td>9.50</td>
                        <td>14</td>
                        <td>12.1</td>
                    </tr>
                    <tr>
                        <td>2.5</td>
                        <td>36/0.30*</td>
                        <td>0.8</td>
                        <td>1</td>
                        <td>9.90</td>
                        <td>18</td>
                        <td>7.41</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>56/0.30**</td>
                        <td>0.8</td>
                        <td>1</td>
                        <td>12.00</td>
                        <td>24</td>
                        <td>4.95</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>84/0.30**</td>
                        <td>0.8</td>
                        <td>1.2</td>
                        <td>13.50</td>
                        <td>31</td>
                        <td>3.30</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>140/0.30**</td>
                        <td>1</td>
                        <td>1.4</td>
                        <td>14.90</td>
                        <td>42</td>
                        <td>1.91</td>
                    </tr>
                    <tr><td colSpan='7'><p>* Class - 2 Flexible Copper Conductor As Per IS 8130</p></td></tr>
                    <tr><td colSpan='7'><p>** Class - 5 Flexible Copper Conductor As Per IS 8130</p></td></tr>
                </tbody>
            </table>
        ),
    };
    return (
        <>
            <div className="about_bg_wrapper">
                <BreadcrbCom link1="/" link2='/products' link1text="Home" link2text="Our Products" active="Cables" title="Copper Round Three Core Cables" />
            </div>
            <div className="container fcsc__wrapper">
                <div className="row mb-5">
                    <div className="col-md-4">
                        <div className="">
                            <img src={fcsub} alt="Flat Copper Submersible Cables" className="" width="100%" />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="cables__main--title">Copper Round Three Core Cables</div>
                        <div className="">
                            <p className="wires__p"><CgChevronDoubleRight color="#fe8a0f" /><span className="advantages__span">Outer Sheath: </span> The insulated conductors are bundled together and covered with an outer sheath made of PVC, XLPE, or another suitable material. The outer sheath provides additional protection against mechanical stresses, abrasion, and environmental factors, ensuring the durability and longevity of the cable.</p>
                            <p className="wires__p"><CgChevronDoubleRight color="#fe8a0f" /><span className="advantages__span">Conductor: </span> The cable comprises Three conductors, all made of copper. Copper is chosen for its excellent electrical conductivity, which ensures efficient transmission of electrical power with minimal losses.</p>
                            <p className="wires__p wires__list--wrapper"><CgChevronDoubleRight color="#fe8a0f" /><span className="advantages__span">Core Arrangement: </span>
                                The three copper conductors are typically arranged in a circular formation within the cable structure. This arrangement helps maintain the cable's flexibility and ease of handling during installation.
                            </p>
                            <img src={fcsubinside} alt="" className="fcsubinside__img" />
                        </div>
                    </div>
                </div>
                <DownloadCatalogue />
                <div className="fc__tabs-wrapper">
                    <div className="tabs">
                        <ul className="tab-list">
                            {Object.keys(tabData).map(tab => (
                                <TabCom
                                    key={tab}
                                    label={tab}
                                    onClick={() => setActiveTab(tab)}
                                    isActive={activeTab === tab}
                                />
                            ))}
                        </ul>
                        <TabContent content={tabData[activeTab]} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CRThreeCoreCablee;