import React from "react";
import CountUp from "react-countup";
import { Zoom, Roll } from "react-awesome-reveal";
import VisibilitySensor from "react-visibility-sensor";
import c1 from "../../assets/images/Experiance_Icon.png";
import c2 from "../../assets/images/Manufacturing_Icon.png";
import c3 from "../../assets/images/Export_Icon.png";
import c4 from "../../assets/images/Distributor_Icon.png";
import './counter.css';

const Counter = () => {
    return (
        <div className="">
            <div className="container">
                <Roll direction="in" triggerOnce={true}>
                    <div className="counter_main-wrapper">
                        <div className="row" style={{rowGap:'30px'}}>
                            <div className="counter_col-wrapper col-md-4">
                                <Zoom direction="down" cascade damping={0.3} triggerOnce={true}>
                                    <div className="counter__wrapper">
                                        <CountUp end={10} redraw={true}>
                                            {({ countUpRef, start }) => (
                                                <VisibilitySensor onChange={start} delayedCall>
                                                    <span ref={countUpRef}>count </span>
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                        +
                                    </div>
                                    <div className="counter_title">Years of experience</div>
                                    <img src={c1} alt="image1" width={80} />
                                </Zoom>
                            </div>
                            <div className="counter_col-wrapper col-md-4">
                                <Zoom direction="down" cascade damping={0.3} triggerOnce={true}>
                                    <div className="counter__wrapper">
                                        <CountUp end={15} redraw={true}>
                                            {({ countUpRef, start }) => (
                                                <VisibilitySensor onChange={start} delayedCall>
                                                    <span ref={countUpRef}>count </span>
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                        +
                                    </div>
                                    <div className="counter_title">Manufacturing products </div>
                                    <img src={c2} alt="image1" width={70} />
                                </Zoom>
                            </div>
                            {/* <div className="counter_col-wrapper col-md-3">
                        <div className="counter__wrapper">
                            <CountUp end={15} redraw={true}>
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span ref={countUpRef}>count </span>
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            +
                        </div>
                        <div className="counter_title">Countries to Export</div>
                        <img src={c3} alt="image1" width={85} />
                    </div> */}
                            <div className="counter_col-wrapper col-md-4">
                                <Zoom direction="down" cascade damping={0.3} triggerOnce={true}>
                                    <div className="counter__wrapper">
                                        <CountUp end={200} redraw={true}>
                                            {({ countUpRef, start }) => (
                                                <VisibilitySensor onChange={start} delayedCall>
                                                    <span ref={countUpRef}>count </span>
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                        +
                                    </div>
                                    <div className="counter_title">Authorised
                                        Distributors</div>
                                    <img src={c4} alt="image1" width={90} />
                                </Zoom>
                            </div>
                        </div>
                    </div>
                </Roll>
            </div>
        </div>
    )
}

export default Counter;