import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';
import './App.css';
import Header from './components/Header/header';
import HeroSlider from './components/HeroSlider/HeroSlider';
import HomeAboutus from './components/HomeAbout/HomeAboutus';
import Footer from './components/Footer/Footer';
import AuthorizedBy from './components/Authorized/AuthorizedBy';
import Counter from './components/Counter/Counter';
import Home from './pages/Home';
import Aboutus from './pages/Aboutus';
import Contact from './pages/Contact';
import Products from './pages/Products';
import Cables from './components/OurProducts/Cables';
import FlatCopperSubmersibleCables from './pages/FlatCopperSubmersible';
import Certifications from './pages/Certification';
import HouseWires from './pages/HouseWires';
import CRTwoCoreCablee from './components/Cables/CRtwoCoreCables';
import CRThreeCoreCablee from './components/Cables/CRThreeCoreCables';
import CRFourCoreCable from './components/Cables/CRFourCoreCables';
import RATwoCoreCable from './components/Cables/RATwoCoreCables';
import RAThreeCoreCable from './components/Cables/RAThreeCoreCables';
import Inquiry from './components/Inquiry/Inquiry';
import skycore_loader from "./assets/images/skycore_loader.gif";
import { useState } from 'react';
import Career from './components/Career/Career';
import RAFourCoreCable from './components/Cables/RAFourCoreCables';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 1500);

  return (
    <>
    {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            position: 'fixed',
            top: 0,
            left: 0,
            // background: 'rgba(255, 255, 255, 0.7)', 
            zIndex: 9999, 
          }}
        >
          <img src={skycore_loader} alt='Loading...'  height={130}/>
        </div>
      ) : (
      <Router>
        <Header />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/aboutus' element={<Aboutus />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/inquiry' element={<Inquiry />} />
          <Route exact path='/certificate' element={<Certifications />} />
          <Route exact path='/products' element={<Products />} />
          <Route exact path='/career' element={<Career />} />
          <Route exact path='/products/cables' element={<Cables />} />
          <Route exact path='/products/wires' element={<HouseWires />} />
          <Route exact path='/products/cables/flat-copper-submersible-cable' element={<FlatCopperSubmersibleCables />} />
          <Route exact path='/products/cables/copper-round-two-core-cable' element={<CRTwoCoreCablee />} />
          <Route exact path='/products/cables/copper-round-three-core-cable' element={<CRThreeCoreCablee />} />
          <Route exact path='/products/cables/copper-round-four-core-cable' element={<CRFourCoreCable />} />
          <Route exact path='/products/cables/round-aluminium-two-core-cable' element={<RATwoCoreCable />} />
          <Route exact path='/products/cables/round-aluminium-three-core-cable' element={<RAThreeCoreCable />} />
          <Route exact path='/products/cables/round-aluminium-four-core-cable' element={<RAFourCoreCable />} />
        </Routes>
        <Footer />
      </Router>
      )}
    </>
  );
}

export default App;
